

























































import { Component, Prop, Vue } from "vue-property-decorator";
let marked = require("marked");

import TextProjectsNL from "@/locales/text-projects.nl.md";
import TextProjectsEN from "@/locales/text-projects.en.md";

import TextCustomNL from "@/locales/text-custom.nl.md";
import TextCustomEN from "@/locales/text-custom.en.md";

import TextTechNL from "@/locales/text-tech.nl.md";
import TextTechEN from "@/locales/text-tech.en.md";

@Component
export default class ServiceComponent extends Vue {
  getCustomText() {
    var md = this.$i18n.locale == "nl" ? TextCustomNL : TextCustomEN;
    marked.setOptions({ breaks: true });
    return marked(md);
  }

  getProjectsText() {
    var md = this.$i18n.locale == "nl" ? TextProjectsNL : TextProjectsEN;
    marked.setOptions({ breaks: true });
    return marked(md);
  }

    getTechText() {
    var md = this.$i18n.locale == "nl" ? TextTechNL : TextTechEN;
    marked.setOptions({ breaks: true });
    return marked(md);
  }
}
